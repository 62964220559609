@import '~normalize.css/normalize.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';

$red: #E21833;
$yellow: #FFD520;
$black: #000;
$white: #fff;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.emoji {
  display: inline-block;
  width: auto;
  height: 1em;
  vertical-align: -0.125em;
}

.app {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .header {
    width: 100%;
    height: 10vh;
    flex-basis: 1;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 600px) {
        height: 30vh;
        display: inline-block;
    }

    .header-left {
        width: 50%;
        padding: 1rem;

        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }
    .header-right {
      width: 50%;
      padding: 1rem;
      .bp3-button {
        float: right;
        margin-right: 10px;

        @media screen and (max-width: 600px) {
            float: left;
        }
      }

        @media screen and (max-width: 600px) {
            width: 100%;
            position: relative;            
        }

    }
  }

  .footer {
    width: 100%;
    padding-top: 1rem;
    p {
      width: 100%;
      text-align: right;
    }
  }

  .background {
    position: relative;
    border: 0px solid red;
    width: 80%;
    height: 80vh;
    flex-basis: 9;
    left: 10%;
  }

  .testudo-canvas-wrapper {
    width: 80%;
    height: 80vh;
    position: absolute;
    top: 0;
    left: 10%;
    div {
        position: relative;
        display: block;
        top: 0;
        left: 0;
        canvas {
            user-select: none;
        }
    }
  }

}

.item-list {
  display: block;
  .item {
    cursor: pointer;
    display: inline-block;
    border: 0px solid gray;
    width: 33%;
    height: 100px;
    margin-top: 40px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

